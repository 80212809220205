<template>
  <div class="default-menu">
    <div class="default-menu__header">
      <div class="default-menu__header-top">
        <span class="default-menu__title"></span>
        <button type="button" class="default-menu__close" @click="$emit('close')" aria-label="Закрыть меню">
          <RemixIconComponent category="system" name="close-fill" />
        </button>
      </div>
    </div>
    <div class="default-menu__body filter-menu__body">
      <CategoryFiltersComponent :is-filter-menu="true" />
    </div>
  </div>
</template>

<script>
import CategoryFiltersComponent from "@/views/catalog/components/CategoryFiltersComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "FilterMenu",
  components: { RemixIconComponent, CategoryFiltersComponent },
};
</script>
