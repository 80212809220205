<template>
  <div class="filter-item" :class="{ 'filter-item__open': open, 'filter-item__opened': opened }">
    <button type="button" class="filter-item__header" @click="open = opened || !open">
      <span class="filter-item__title">{{ title }}</span>
      <RemixIconComponent v-if="!opened" category="system" name="arrow-down-s-line" />
    </button>
    <div class="filter-item__body" v-if="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "FilterItemComponent",
  components: { RemixIconComponent },
  props: {
    title: String,
    opened: Boolean,
  },
  data() {
    return {
      open: true,
    };
  },
};
</script>

<style lang="stylus">
.filter-item {
  display grid
  align-items start
  background: var(--white);
  border: 1px solid var(--border-color-2);
  border-radius: 16px;
  padding 16px
  &__open &__header {
    .icon {
      transform rotate(180deg)
    }
  }

  &:not(&__opened) &__header {
    cursor pointer

    &:hover {
      color var(--brown-light)
    }
  }

  &__header {
    display flex
    align-items center
    justify-content space-between
    border none
    background none
    font-size: 1.125em
    font-weight: 500;
    line-height: 120%;
    padding-bottom 18px
    color: var(--gray-900);

    .icon {
      width 12px
      height: 12px
    }
  }

  &__title {
    display: flex;
    align-items: center;
    text-align left
    transition color var(--transition)
  }
  &__body {}
}
</style>
